<template>
  <div id="app">
    <div class="message" style="top: 44px; z-index: 1207;" v-show="alertFlag">
      <div class="message-notice" style="height: 54px;">
        <div class="message-notice-content message-notice-content-info">
          <div class="message-notice-content-text">
            <div class="message-custom-content message-info">
              <div class="alert-circle">
                <span class="alert-icon">!</span>
              </div>
              <span style="margin-left:10px;">{{ alertMsg }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-info" >
      <div style="text-align: center;">
        <h2>欢迎莅临，请赐信息 Welcome!</h2>
        <h2> Please Share Your Information</h2>
      </div>
      <div v-show="!successFlag" style="width:100%;display: flex;justify-content: center;align-items: center;flex-direction: column;">
        <div class="input-item">
          <div class="label-info required-label-info">姓名(Name):</div>
          <div class="input-wrapper">
            <input v-model="inputData.name" class="input-info" type="text">
          </div>
        </div>
        <div class="input-item">
          <div class="label-info required-label-info">电话(Phone):</div>
          <div class="input-wrapper">
            <input v-model="inputData.phone" class="input-info" type="number">
          </div>
        </div>
        <div class="input-item">
          <div class="label-info">邮箱(Email):</div>
          <div class="input-wrapper">
            <input class="input-info" v-model="inputData.email" type="text">
          </div>
        </div>
        <div class="input-item">
          <div class="label-info required-label-info">公司信息(Company Information):</div>
          <div class="input-wrapper">
            <input class="input-info" v-model="inputData.companyInfo" type="text">
          </div>
        </div>
        <div class="input-item">
          <div class="label-info">职业(Occupation):</div>
          <div class="input-wrapper">
            <input class="input-info" v-model="inputData.career" type="text">
          </div>
        </div>

        <div class="button-info" style="">
          <div class="submit-button" @click="submitOperation"><span>提交(Submit)</span></div>
        </div>
      </div>
      <div class="success-info" v-show="successFlag">
        <div style="display:flex;align-items: center;">
          <div class="green-circle">
            <span class="green-icon">✔</span>
          </div>
          <span style="margin-left:10px;">信息提交成功(Information submitted successfully)!</span>
        </div>
      </div>

    </div>

    <div class="unit-info">
      <h3>合作单位(Collaborating Organization)</h3>
      <div style="display:flex;flex-direction: column;justify-content: center;align-items: center;">
        <div style="display:flex;">
          <div style="width:49%">
            <img style="width:85%;margin-top:12px;margin-left:18px;"
                 src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/extra/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240927141151.jpg"
                 alt=""/>
          </div>
          <div style="width:49%">
            <img style="width:58%;margin-top:6px;margin-left: 40px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/extra/np-2.jpg"
                 alt="">
          </div>
        </div>

        <div style="display:flex;margin-bottom: 40px;">
          <div style="width:49%">
            <img style="width:100%;margin-top:-13px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/extra/zdxznkj.png"
                 alt="">
          </div>
          <div style="width:49%;">
            <img style="width:100%;margin-top:-16px;margin-bottom: 40px;"
                 src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/extra/aytkj.png" alt="">
          </div>
        </div>



      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'App',
  data() {
    return {
      successFlag:false,
      alertFlag: false,
      alertMsg:'',
      loadingFlag: false,
      inputData: {
        name: '',
        phone: '',
        email: '',
        companyInfo: '',
        career: '',
      }
    }
  },
  mounted() {
    document.title = 'Conference & Expo 2024';
  },
  methods: {
    isValidPhone(phone) {
      // 正则表达式验证手机号（以中国手机号为例）
      const re = /^1[3-9]\d{9}$/; // 可以根据需要调整为其他国家的格式
      return re.test(phone);
    },
    showMsg(msg) {
      this.alertMsg = msg;
      this.alertFlag =true;
      setTimeout(() => {
        this.alertFlag =false;
      }, 1500);
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 邮箱格式正则
      return re.test(email);
    },
    submitOperation() {
      if (this.loadingFlag) {
        return;
      }

      this.loadingFlag = true;

      //判断必填项是否填写
      if (this.inputData.name == '' || this.inputData.phone == ''||this.inputData.companyInfo == '') {
        this.showMsg("姓名、手机号和公司信息是必填的！");
        this.loadingFlag = false;
        return;
      }

      // if (!this.isValidPhone(this.inputData.phone)) {
      //   this.showMsg("手机号格式不正确");
      //   this.loadingFlag = false;
      //   return;
      // }

      //邮箱填写时，检测邮箱格式是否正常
      if (this.inputData.email !='' && !this.isValidEmail(this.inputData.email)) {
        this.showMsg("邮箱格式不正确");
        this.loadingFlag = false;
        return;
      }

      axios.post('api/companySubmit', this.inputData).then(res => {
        if (res.status == 200) {
          this.successFlag = true;
          this.resetInputContent();
        }
      }).catch(error => {
        console.error('Error fetching users:', error);
        this.loadingFlag = false;
      });


    },
    resetInputContent() {
      this.inputData.name = '';
      this.inputData.phone = '';
      this.inputData.email = '';
      this.inputData.companyInfo = '';
      this.inputData.career = '';
      this.loadingFlag = false;
    }
  },
  components: {}
}
</script>

<style>
.message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 16px;
  left: 0;
  pointer-events: none
}

.message-notice:first-child {
  margin-top: -8px
}

.message-notice-content {
  display: inline-block;
  pointer-events: all;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
  background: #fff;
  position: relative
}

.message-notice-content-text {
  display: inline-block;
  padding-right: 32px;
}

.message-custom-content {
  display: flex;
  align-items: center;
}

.message-custom-content span {
  vertical-align: middle;
}

.alert-circle {
  width: 18px; /* 圆形的宽度 */
  height: 18px; /* 圆形的高度 */
  background-color: #ed4014; /* 红色背景 */
  border-radius: 50%; /* 圆形 */
  display: flex; /* 使用 flexbox 居中内容 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  color: white; /* 文本颜色 */
  font-size: 12px; /* 字体大小 */
}

.green-circle {
  width: 18px; /* 圆形的宽度 */
  height: 18px; /* 圆形的高度 */
  background-color: #19be6b; /* 红色背景 */
  border-radius: 50%; /* 圆形 */
  display: flex; /* 使用 flexbox 居中内容 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  color: white; /* 文本颜色 */
  font-size: 12px; /* 字体大小 */
}

.green-icon, .alert-icon {
  font-weight: bold; /* 加粗感叹号 */
}


#app {
  width: 100vw;
  color: #2c3e50;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f8f8f9;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

.unit-info {
  background-color: #fff;
  margin: 10px;
  padding: 10px;
}
.success-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 80px 20px;
}
.main-info {
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
}

.input-item {
  margin-bottom: 24px;
  vertical-align: top;
  zoom: 1;
  width: 80%;
}

.submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #2775ff;
  background-color: #2775ff;
  color: #fff;
  line-height: 1.5;
  font-weight: 400;
  cursor: pointer;
  height: 42px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  width: 50%;
  margin-bottom: 30px;
}

.button-info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-info {
  color: #515a6e;
  line-height: 1;
  display: inline-block;
  padding: 0 0 10px 0;
}

.required-label-info:before {
  content: "*";
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  font-size: 14px;
  color: #ed4014;
}

.input-info {
  display: inline-block;
  width: 100%;
  height: 42px;
  line-height: 1.5;
  padding: 0px 7px;
  font-size: 14px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border .2s ease-in-out, background .2s ease-in-out, box-shadow .2s ease-in-out;
}

.input-info:focus, .input-info:hover {
  border-color: #2775ff !important;
  box-shadow: 0 0 0 1px rgba(45, 140, 240, .2);
  outline: none; /* 去除默认的焦点轮廓 */
}

.input-wrapper {
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: middle;
  line-height: normal;
}
</style>
